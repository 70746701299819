import React, { useEffect, useState } from 'react';
import './SettingsPage.css';
import BottomNavigation from '../components/BottomNavigation';
import axios from 'axios';
import { Link } from 'react-router-dom';

const token = localStorage.getItem('userToken');

const InputForm = () => {
  const [businessName, setBusinessName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [notification, setNotification] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchOrderCode = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/setting`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Add token to the request headers
            },
        });
        setBusinessName(response.data.data.name)
        setBusinessEmail(response.data.data.websiteData.email_address)
        setAddress(response.data.data.websiteData.address)
        setPostalCode(response.data.data.websiteData.postal_code)
        setCity(response.data.data.websiteData.city)
        setBusinessPhone(response.data.data.websiteData.phone_number)
        // setEmail(response.data.data.websiteData.email_address)
      } catch (error) {
        console.error('Fout bij het ophalen van gegevens:', error);
      }
    };

    fetchOrderCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: at least one of email or phone number is required
    if (!address && !businessPhone) {
      setNotification({ message: 's "Geef alstublieft een e-mail of telefoonnummer op.', type: 'error' },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
      return;
    }

    setIsSubmitting(true);

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/admin/setting/update-lite`,
            {
              businessName,
              businessEmail,
              businessPhone,
              address,
              postalCode,
              city,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
              },
            }
        );

        if (response.data.message == 'Updated successfully') {
            setNotification({ message: 'Profiel succesvol bijgewerkt! ', type: 'success' });
            setIsSubmitting(false);
            // Show confirmation button and refresh page
        } else {
            setNotification({ message: 'Failed to update profile. Please try again.', type: 'error' });
            setIsSubmitting(false); 
        }
    } catch (error) {
        setNotification({ message: 'An error occurred while updating the profile.', type: 'error' });
        setIsSubmitting(false);
    }
  };

  const handleConfirm = () => {
    window.location.reload();
  };

  const logout = () => {
    if (window.confirm("Weet u zeker dat u wilt uitloggen?")) {
      localStorage.removeItem('isLogin');
      localStorage.removeItem('userToken');
      localStorage.removeItem('userName');
      window.location.reload(); // Refresh the page
    } else {
      // Do nothing if the user cancels
    }
  };  

  return (
    <div className='setting-container'>
        <div className="header">
            <h3 className='setting-form-title'>Bedrijfsprofiel</h3>
            {/* <div className='buttton-right-top'> */}
              <div onClick={logout} className="home-icon">
                <svg style={{paddingRight: '10px'}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.6667 17H15.2222C15.6937 17 16.1459 16.8127 16.4793 16.4793C16.8127 16.1459 17 15.6937 17 15.2222V2.77778C17 2.30628 16.8127 1.8541 16.4793 1.5207C16.1459 1.1873 15.6937 1 15.2222 1H11.6667M5.44444 13.4444L1 9M1 9L5.44444 4.55556M1 9H11.6667" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Uitloggen
              </div>
              {/* <Link to="/" className="home-icon">
                  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.66667 17V9H10.3333V17M1 6.6L8 1L15 6.6V15.4C15 15.8243 14.8361 16.2313 14.5444 16.5314C14.2527 16.8314 13.857 17 13.4444 17H2.55556C2.143 17 1.74733 16.8314 1.45561 16.5314C1.16389 16.2313 1 15.8243 1 15.4V6.6Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </Link> */}
            {/* </div> */}
        </div>
        <hr></hr>
        <div className="input-form-container">
            <form onSubmit={handleSubmit}>
                {/* <label htmlFor="businessName">Client Name:</label> */}
                <input
                  type="text"
                  className='setting-input-field'
                  placeholder='Bedrijfsnaam'
                  id="businessEmail"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  required
                />

                {/* <label htmlFor="address">Client E-mail:</label> */}
                <input
                  type="email"
                  className='setting-input-field'
                  placeholder='E-mail'
                  id="address"
                  value={businessEmail}
                  onChange={(e) => setBusinessEmail(e.target.value)}
                />

                {/* <label htmlFor="address">Client E-mail:</label> */}
                <input
                  type="text"
                  className='setting-input-field'
                  placeholder='Adres'
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />

                {/* <label htmlFor="postal">Postal Code</label> */}
                <input
                  type="text"
                  className='setting-input-field'
                  placeholder='Postcode'
                  id="postal"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />

                {/* <label htmlFor="city">City</label> */}
                <input
                  type="text"
                  className='setting-input-field'
                  placeholder='Stad'
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />

                {/* <label htmlFor="businessPhone">Client Phone Number:</label> */}
                <input
                  type="tel"
                  className='setting-input-field'
                  placeholder='Telefoonnummer'
                  id="businessPhone"
                  value={businessPhone}
                  onChange={(e) => setBusinessPhone(e.target.value)}
                />

                {/* <label htmlFor="email">email:</label> */}
                {/* <input
                  type="email"
                  className='setting-input-field'
                  placeholder='E-mail'
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                /> */}

                {!isSubmitting && notification?.type !== 'success' && (
                    <button type="submit">Profiel bijwerken</button>
                )}
            </form>

            {isSubmitting && (
                <div className="loading">
                    <h3>Laden...</h3>
                </div>
            )}

            {notification && (
                <div className={`notification ${notification.type}`}>
                {notification.message}
                {notification.type === 'success' && <button onClick={handleConfirm}>Bevestigen</button>}
                </div>
            )}
            </div>
      <BottomNavigation />
    </div>
  );
};

export default InputForm;
