import React, { useEffect, useState } from 'react';
import './OrderForm.css';
import BottomNavigation from '../components/BottomNavigation';
import axios from 'axios';
import { Link } from 'react-router-dom';

const token = localStorage.getItem('userToken');

const InputForm = () => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [orderNote, setOrderNote] = useState('');
  const [communicationMethod, setCommunicationMethod] = useState('');
  const [companyOrderNumber, setCompanyOrderNumber] = useState('');
  const [trackNtrace, setTrackNtrace] = useState('');
  const [notification, setNotification] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchOrderCode = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/order/track_number`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Add token to the request headers
            },
        });
        setTrackNtrace(response.data.data); // Set the default order code
      } catch (error) {
        console.error('Error fetching order code:', error);
      }
    };

    fetchOrderCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: at least one of email or phone number is required
    // if (!clientEmail && !clientPhone) {
    //   setNotification({ message: 'Please provide email and phone number.', type: 'error' },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    //       },
    //     });
    //   return;
    // }

    setIsSubmitting(true);

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/admin/order/place-order`,
            {
              clientName,
              clientEmail,
              clientPhone,
              communicationMethod,
              companyOrderNumber,
              trackNtrace,
              orderNote
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
              },
            }
        );

        if (response.data.success) {
            setNotification({ message: 'Order succesvol geplaatst! ', type: 'success' });
            setIsSubmitting(false);
            // Show confirmation button and refresh page
        } else {
            setNotification({ message: 'Het plaatsen van de order is mislukt. Probeer het alstublieft opnieuw.', type: 'error' });
            setIsSubmitting(false); 
        }
        } catch (error) {
            setNotification({ message: 'Er is een fout opgetreden bij het plaatsen van de order.', type: 'error' });
            setIsSubmitting(false);
        }
  };

  const handleConfirm = () => {
    window.location.reload();
  };

  return (
    <div className='order-container'>
        <div className="header">
            <h3 className='order-form-title'>OrderAlert aanmaken</h3>
            {/* <Link to="/" className="home-icon">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.66667 17V9H10.3333V17M1 6.6L8 1L15 6.6V15.4C15 15.8243 14.8361 16.2313 14.5444 16.5314C14.2527 16.8314 13.857 17 13.4444 17H2.55556C2.143 17 1.74733 16.8314 1.45561 16.5314C1.16389 16.2313 1 15.8243 1 15.4V6.6Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </Link> */}
        </div>
        <hr></hr>
        <div className="input-form-container">
            <form onSubmit={handleSubmit}>
                {/* <label htmlFor="clientName">Client Name:</label> */}
                <input
                type="text"
                className='order-input-field'
                placeholder='Klantnaam'
                id="clientName"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                required
                />

                {/* <label htmlFor="clientEmail">Client E-mail:</label> */}
                <input
                type="email"
                className='order-input-field'
                placeholder='E-mail'
                id="clientEmail"
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
                />

                {/* <label htmlFor="clientPhone">Client Phone Number:</label> */}
                <input
                type="tel"
                className='order-input-field'
                placeholder='Klant mobiel nummer'
                id="clientPhone"
                value={clientPhone}
                onChange={(e) => setClientPhone(e.target.value)}
                />

                {/* Show radio buttons if both email and phone are filled */}
                {/* {clientEmail && clientPhone && (
                <>
                    <div className='order-notif-options'>
                        <label>Bijgewerkt door:</label>
                        <input
                            type="radio"
                            className='order-input-field'
                            id="email"
                            name="communicationMethod"
                            value="email"
                            onChange={(e) => setCommunicationMethod(e.target.value)}
                        />
                        <label htmlFor="email">E-mail</label>

                        <input
                            type="radio"
                            className='order-input-field'
                            id="wa"
                            name="communicationMethod"
                            value="wa"
                            onChange={(e) => setCommunicationMethod(e.target.value)}
                        />
                        <label htmlFor="wa">WhatsApp</label>
                    </div>
                </>
                )} */}

                {/* <label htmlFor="companyOrderNumber">Company Order Number:</label> */}
                <input
                type="text"
                className='order-input-field'
                placeholder='Eigen ordernummer'
                id="companyOrderNumber"
                value={companyOrderNumber}
                onChange={(e) => setCompanyOrderNumber(e.target.value)}
                />

                {/* <label htmlFor="defaultValue">Track and Trace Number:</label> */}
                <textarea
                placeholder='Schrijf een notitie'
                className='order-input-field'
                id="noteValue"
                onChange={(e) => setOrderNote(e.target.value)}
                value={orderNote}
                />

                <label htmlFor="defaultValue">OrderAlert nummer:</label>
                <input
                type="text"
                className='order-input-field'
                id="defaultValue"
                value={trackNtrace}
                disabled
                />

            {!isSubmitting && notification?.type !== 'success' && (
                <button type="submit">Plaats Order</button>
            )}
            </form>

            {isSubmitting && (
                <div className="loading">
                    <h3>Laden...</h3>
                </div>
            )}

            {notification && (
                <div className={`notification ${notification.type}`}>
                {notification.message}
                {notification.type === 'success' && <button onClick={handleConfirm}>Bevestigen</button>}
                </div>
            )}
            </div>
        <BottomNavigation />
    </div>
  );
};

export default InputForm;
