import React from 'react';
import './ConfirmationModal.css'; // Create CSS for this modal

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message, desc }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p><b>{message}</b>
        <br></br>{desc}</p>
        <br></br>
        <div className="confirmation-buttons">
          <button onClick={onConfirm} className="confirm-button">Ja</button>
          <button onClick={onClose} className="cancel-button">Nee</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
