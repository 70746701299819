import React from 'react';
import BottomNavigation from '../components/BottomNavigation';

const ThankYouPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Bedankt voor je abonnement bij ons!</h1>
      <p style={styles.message}>
        We waarderen je vertrouwen in onze diensten. Als je vragen hebt, neem dan gerust contact met ons op.
      </p>
      <BottomNavigation />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
  },
  title: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '10px',
  },
  message: {
    fontSize: '18px',
    color: '#555',
  },
};

export default ThankYouPage;
