import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ClientPage.css';
import BottomNavigation from '../components/BottomNavigation';
// import OrderModal from './OrderModal';
import { useNavigate } from 'react-router-dom';

const token = await localStorage.getItem('userToken');

const ClientPage = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOrderClick = (order) => {
    navigate(`/client-detail/${order.id}`); // Navigate to the client detail page with the client ID
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/order/order-list`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Add token to the request headers
          },
        });
        setOrders(response.data.data);
      } catch (error) {
        console.error('Fout bij ophalen orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const handleStatusUpdate = async (orderId, newStatus) => {
    try {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/update_status/${orderId}/${newStatus}?email=1`, {
            headers: {
                'Authorization': `Bearer ${token}`,  // Add your token here
            },
        });
        // Optionally refresh the list of orders after updating the status
        // fetchOrders();
        window.location.reload();
    } catch (error) {
        console.error("Gegevens bijwerken mislukt", error);
        // window.location.reload();
    }
  };

  const filteredOrders = orders.filter(order =>
    (order.name && order.name.toLowerCase().startsWith(search.toLowerCase())) ||
    (order.code && order.code.toLowerCase().startsWith(search.toLowerCase())) ||
    (order.company_order_number && order.company_order_number.toLowerCase().startsWith(search.toLowerCase())) ||
    (order.email && order.email.toLowerCase().startsWith(search.toLowerCase())) ||
    (order.phone && order.phone.toLowerCase().startsWith(search.toLowerCase())) ||
    (order.status && order.status.toLowerCase().startsWith(search.toLowerCase()))
  );

  const clearSearch = () => {
    setSearch('');
  }

  const getStatusIcon = (order) => {
    switch (order.status) {
      case 'delivered':
        return (
          <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2107 13.1317L14.316 17.2369L28.0002 3.55273" stroke="#84CC16" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.6316 14.5001V24.0791C26.6316 24.8049 26.3432 25.501 25.83 26.0143C25.3167 26.5276 24.6206 26.8159 23.8947 26.8159H4.73684C4.01099 26.8159 3.31486 26.5276 2.8016 26.0143C2.28834 25.501 2 24.8049 2 24.0791V4.92117C2 4.19531 2.28834 3.49919 2.8016 2.98593C3.31486 2.47267 4.01099 2.18433 4.73684 2.18433H19.7895" stroke="#84CC16" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>                  
        );
      case 'processing':
        return (
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.392 1.89345C21.6318 1.91626 27.1748 6.55112 28.2906 12.8732C28.4221 13.6181 28.4533 14.3815 28.5175 15.1372C28.5445 15.4541 28.6357 15.6024 28.868 15.588C29.1663 15.57 29.1975 15.3611 29.1981 15.1186C29.2101 9.04382 25.0729 3.54104 19.1703 1.78481C12.802 -0.109473 5.96919 2.70434 2.80365 8.54744C1.30311 11.3174 0.794729 14.2753 1.2689 17.3934C1.63743 19.8129 2.54556 22.0169 4.07851 23.9262C6.18287 26.5473 8.87303 28.2837 12.1796 28.9788C13.1028 29.1727 14.0595 29.2123 15.0024 29.3041C15.3188 29.3347 15.503 29.1805 15.4982 28.9578C15.494 28.7375 15.32 28.6193 14.9946 28.6121C11.1809 28.531 7.9391 27.1157 5.36117 24.3151C2.74063 21.4683 1.49218 18.0639 1.84631 14.1979C2.29887 9.2665 4.79697 5.62799 9.18215 3.31596C11.0554 2.3292 13.0745 1.89585 14.392 1.89345Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M23.9063 23.801C20.8101 23.8466 18.2585 26.0276 17.9277 28.9499C17.909 29.1157 17.9066 29.2834 17.9028 29.4501C17.8975 29.6743 18.003 29.8142 18.177 29.8C18.3851 29.7827 18.4436 29.6511 18.4493 29.4661C18.5002 27.8851 19.1555 26.5757 20.4265 25.5519C23.5141 23.0638 28.4288 24.6831 29.2275 28.4537C29.298 28.7863 29.3176 29.1294 29.3488 29.4688C29.3651 29.6465 29.4111 29.7887 29.6278 29.7987C29.8048 29.8069 29.9016 29.6766 29.9026 29.4474C29.9117 26.9256 27.9139 24.5405 25.322 24.0001C24.8575 23.9031 24.3785 23.8657 23.9063 23.801Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M23.9291 22.8009C22.2806 22.817 20.9107 21.4622 20.9026 19.8081C20.8946 18.1596 22.2337 16.8086 23.8827 16.8011C25.5554 16.7935 26.895 18.1175 26.9026 19.7849C26.9097 21.4348 25.5785 22.7848 23.9291 22.8009ZM23.8898 22.2484C25.2649 22.2522 26.3685 21.1515 26.3676 19.7768C26.3666 18.4581 25.2512 17.3431 23.9225 17.3337C22.5686 17.3242 21.4542 18.433 21.4528 19.791C21.4513 21.1477 22.5398 22.2446 23.8898 22.2484Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M15.0533 15.7046C15.9591 14.9081 16.8438 14.1309 17.7279 13.3524C18.9127 12.3086 20.0964 11.2642 21.2806 10.2204C21.5453 9.98696 21.7488 9.96055 21.903 10.1382C22.0525 10.3111 22.0008 10.5037 21.7277 10.7444C20.0495 12.2252 18.3701 13.7047 16.6907 15.1842C16.2412 15.5804 15.7916 15.9777 15.339 16.3709C15.0683 16.6067 14.9159 16.6031 14.6788 16.3342C13.4027 14.8889 12.1315 13.4388 10.8554 11.9929C10.6976 11.814 10.6213 11.6364 10.8092 11.4413C10.9581 11.2864 11.1868 11.3296 11.3752 11.5439C12.4922 12.8098 13.6074 14.0774 14.7238 15.3445C14.8229 15.4561 14.9255 15.5648 15.0533 15.7046Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M14.8003 5.70489C14.8003 5.28474 14.8039 4.86519 14.7991 4.44503C14.7961 4.19895 14.8855 4.02428 15.1502 4.02368C15.3999 4.02308 15.4948 4.19535 15.4954 4.42463C15.4972 5.30454 15.499 6.18386 15.4936 7.06378C15.4923 7.27865 15.3861 7.44251 15.1562 7.44551C14.9137 7.44851 14.8009 7.28886 14.7991 7.05417C14.7967 6.60401 14.7985 6.15445 14.7985 5.70429C14.7997 5.70489 14.7997 5.70489 14.8003 5.70489Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M14.7991 24.7698C14.7991 24.3401 14.7955 23.9097 14.8003 23.4799C14.8033 23.2099 14.948 23.0376 15.1599 23.0592C15.4048 23.0838 15.4948 23.2555 15.4954 23.4793C15.4978 24.3497 15.4972 25.2194 15.496 26.0897C15.4954 26.3208 15.3928 26.4894 15.1479 26.4882C14.9054 26.487 14.7985 26.3268 14.7991 26.0909C14.7997 25.6497 14.7991 25.2098 14.7991 24.7698Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M5.65911 14.9169C6.08886 14.9169 6.51802 14.9217 6.94777 14.9151C7.18486 14.9115 7.35292 15.0076 7.35772 15.2471C7.36252 15.4859 7.20226 15.5946 6.96278 15.594C6.08346 15.5916 5.20475 15.5928 4.32543 15.594C4.09015 15.5946 3.92629 15.4955 3.93229 15.2477C3.93829 15.001 4.10515 14.9127 4.34044 14.9157C4.78039 14.9211 5.21975 14.9169 5.65911 14.9169Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
          </svg>              
        );
      case 'ready_for_pickup':
        return (
          <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 9.43042C2 9.15428 2.22386 8.93042 2.5 8.93042H25.7577C26.0338 8.93042 26.2577 9.15428 26.2577 9.43042V32C26.2577 34.7614 24.0191 37 21.2577 37H7C4.23857 37 2 34.7614 2 32V9.43042Z" stroke="#FFD964" stroke-width="2.5"/>
            <path d="M6.50513 13.9553V9.45033C6.50513 5.33549 9.84086 1.99976 13.9557 1.99976V1.99976C18.0705 1.99976 21.4063 5.33549 21.4063 9.45033V13.9553" stroke="#FFD964" stroke-width="2.5"/>
          </svg>                
        );
      default:
        return (
          <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.392 1.89345C21.6318 1.91626 27.1748 6.55112 28.2906 12.8732C28.4221 13.6181 28.4533 14.3815 28.5175 15.1372C28.5445 15.4541 28.6357 15.6024 28.868 15.588C29.1663 15.57 29.1975 15.3611 29.1981 15.1186C29.2101 9.04382 25.0729 3.54104 19.1703 1.78481C12.802 -0.109473 5.96919 2.70434 2.80365 8.54744C1.30311 11.3174 0.794729 14.2753 1.2689 17.3934C1.63743 19.8129 2.54556 22.0169 4.07851 23.9262C6.18287 26.5473 8.87303 28.2837 12.1796 28.9788C13.1028 29.1727 14.0595 29.2123 15.0024 29.3041C15.3188 29.3347 15.503 29.1805 15.4982 28.9578C15.494 28.7375 15.32 28.6193 14.9946 28.6121C11.1809 28.531 7.9391 27.1157 5.36117 24.3151C2.74063 21.4683 1.49218 18.0639 1.84631 14.1979C2.29887 9.2665 4.79697 5.62799 9.18215 3.31596C11.0554 2.3292 13.0745 1.89585 14.392 1.89345Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M23.9063 23.801C20.8101 23.8466 18.2585 26.0276 17.9277 28.9499C17.909 29.1157 17.9066 29.2834 17.9028 29.4501C17.8975 29.6743 18.003 29.8142 18.177 29.8C18.3851 29.7827 18.4436 29.6511 18.4493 29.4661C18.5002 27.8851 19.1555 26.5757 20.4265 25.5519C23.5141 23.0638 28.4288 24.6831 29.2275 28.4537C29.298 28.7863 29.3176 29.1294 29.3488 29.4688C29.3651 29.6465 29.4111 29.7887 29.6278 29.7987C29.8048 29.8069 29.9016 29.6766 29.9026 29.4474C29.9117 26.9256 27.9139 24.5405 25.322 24.0001C24.8575 23.9031 24.3785 23.8657 23.9063 23.801Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M23.9291 22.8009C22.2806 22.817 20.9107 21.4622 20.9026 19.8081C20.8946 18.1596 22.2337 16.8086 23.8827 16.8011C25.5554 16.7935 26.895 18.1175 26.9026 19.7849C26.9097 21.4348 25.5785 22.7848 23.9291 22.8009ZM23.8898 22.2484C25.2649 22.2522 26.3685 21.1515 26.3676 19.7768C26.3666 18.4581 25.2512 17.3431 23.9225 17.3337C22.5686 17.3242 21.4542 18.433 21.4528 19.791C21.4513 21.1477 22.5398 22.2446 23.8898 22.2484Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M15.0533 15.7046C15.9591 14.9081 16.8438 14.1309 17.7279 13.3524C18.9127 12.3086 20.0964 11.2642 21.2806 10.2204C21.5453 9.98696 21.7488 9.96055 21.903 10.1382C22.0525 10.3111 22.0008 10.5037 21.7277 10.7444C20.0495 12.2252 18.3701 13.7047 16.6907 15.1842C16.2412 15.5804 15.7916 15.9777 15.339 16.3709C15.0683 16.6067 14.9159 16.6031 14.6788 16.3342C13.4027 14.8889 12.1315 13.4388 10.8554 11.9929C10.6976 11.814 10.6213 11.6364 10.8092 11.4413C10.9581 11.2864 11.1868 11.3296 11.3752 11.5439C12.4922 12.8098 13.6074 14.0774 14.7238 15.3445C14.8229 15.4561 14.9255 15.5648 15.0533 15.7046Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M14.8003 5.70489C14.8003 5.28474 14.8039 4.86519 14.7991 4.44503C14.7961 4.19895 14.8855 4.02428 15.1502 4.02368C15.3999 4.02308 15.4948 4.19535 15.4954 4.42463C15.4972 5.30454 15.499 6.18386 15.4936 7.06378C15.4923 7.27865 15.3861 7.44251 15.1562 7.44551C14.9137 7.44851 14.8009 7.28886 14.7991 7.05417C14.7967 6.60401 14.7985 6.15445 14.7985 5.70429C14.7997 5.70489 14.7997 5.70489 14.8003 5.70489Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M14.7991 24.7698C14.7991 24.3401 14.7955 23.9097 14.8003 23.4799C14.8033 23.2099 14.948 23.0376 15.1599 23.0592C15.4048 23.0838 15.4948 23.2555 15.4954 23.4793C15.4978 24.3497 15.4972 25.2194 15.496 26.0897C15.4954 26.3208 15.3928 26.4894 15.1479 26.4882C14.9054 26.487 14.7985 26.3268 14.7991 26.0909C14.7997 25.6497 14.7991 25.2098 14.7991 24.7698Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
            <path d="M5.65911 14.9169C6.08886 14.9169 6.51802 14.9217 6.94777 14.9151C7.18486 14.9115 7.35292 15.0076 7.35772 15.2471C7.36252 15.4859 7.20226 15.5946 6.96278 15.594C6.08346 15.5916 5.20475 15.5928 4.32543 15.594C4.09015 15.5946 3.92629 15.4955 3.93229 15.2477C3.93829 15.001 4.10515 14.9127 4.34044 14.9157C4.78039 14.9211 5.21975 14.9169 5.65911 14.9169Z" fill="#FA812D" stroke="#FA812D" stroke-width="1.2"/>
          </svg>
        );
    }
  };

  return (
    <div className="client-page">
        <div className='sticky-header'>
          <div className="header">
              <h3 className='client-form-title'>Klantupdate</h3>
              {/* <Link to="/"  className="home-icon">
                  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.66667 17V9H10.3333V17M1 6.6L8 1L15 6.6V15.4C15 15.8243 14.8361 16.2313 14.5444 16.5314C14.2527 16.8314 13.857 17 13.4444 17H2.55556C2.143 17 1.74733 16.8314 1.45561 16.5314C1.16389 16.2313 1 15.8243 1 15.4V6.6Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </Link> */}
          </div>
          <div className="search-bar">
              <div className="input-wrapper">
                <span className="search-icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 19L14.65 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" stroke="#2E6DE9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
                <input
                  className="input-search-bar"
                  type="text"
                  placeholder="Zoeken"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span onClick={clearSearch} className="clear-icon">
                  <svg style={{marginLeft: '-8vw'}} width="12" height="12" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9M9 1L1 9" stroke="#ACB5BB" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </div>
            </div>
            <hr style={{width: '90vw'}}></hr>
        </div>
        <div className="order-list">
            {search ? filteredOrders.map(order => (
            <div key={order.id} className="order-item" onClick={() => handleOrderClick(order)}>
                <div className="order-icon">
                    {getStatusIcon(order)}
                    {/* <span>{order.status == 'ready_for_pickup' ? 'ready for pickup' : order.status}</span> */}
                </div>
                <div className="order-info">
                    <div className="order-name">
                        <h3 className='client-client-name'>{order.name}</h3>
                        <span className='client-date'>{order.delivery_date}</span>
                    </div>
                    <div className="order-status">
                        <p className='gray'>Bonnr. {order.company_order_number??'-'} | Alertnr. {order.code}</p>
                    </div>
                    {/* <div className="order-status">
                        <span>Status: {order.status == 'ready_for_pickup' ? 'ready for pickup' : order.status}</span>
                    </div> */}
                </div>
            </div>
            )) : 
              <div className='fixed'>
                <p className='empty-data'>Zoek a.u.b. op klantnaam, <br></br>
                Order Alert-nummer, bedrijfsorder-nummer, mobiele nummer of e-mail.</p>
              </div>
            }

            {/* <OrderModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                onUpdateStatus={handleStatusUpdate} 
                order={selectedOrder} 
            /> */}
        </div>
        <BottomNavigation />
    </div>
  );
};

export default ClientPage;
