import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import './App.css';
import LoginPage from './screens/login/LoginPage';
import Dashboard from './screens/dashboard/Dashboard'; 
import OrderForm from './screens/order/OrderForm'; 
import ClientPage from './screens/client/ClientPage'; 
import ClientDetailPage from './screens/client/ClientDetailPage'; 
import OverviewPage from './screens/overview/OverviewPage'; 
import SettingsPage from './screens/settings/SettingsPage'; 
import SubscriptionComponent from './screens/payment/SubscriptionComponent'; 
import ThankYouPage from './screens/payment/Thankyou'; 

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Initialize as null
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Simulate async loading of login status
    const checkLoginStatus = async () => {
      const loginStatus = await localStorage.getItem('isLogin') === 'true';
      setIsLoggedIn(loginStatus);
      setLoading(false);
      console.log('one');
    };
    checkLoginStatus();
  }, []);

  // If loading, show a loading indicator
  if (loading) {
    console.log('two');
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <h1>Laden...</h1>
      </div>
    );
  } else if (!isMobile) {
    console.log('desktop');
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <h1>Welkom</h1>
      </div>
    );
  } else {
    console.log('three');
    return (
      <Router>
        <Routes>
          <Route path="/" element={isLoggedIn ? <Dashboard /> : <LoginPage />} />
          <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <LoginPage />} />
          {/* <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} /> */}
          <Route path="/order" element={isLoggedIn ? <OrderForm /> : <Navigate to="/login" />} />
          <Route path="/client" element={isLoggedIn ? <ClientPage /> : <Navigate to="/login" />} />
          <Route path="/client-detail/:orderId" element={<ClientDetailPage />} />
          <Route path="/overview" element={isLoggedIn ? <OverviewPage /> : <Navigate to="/login" />} />
          <Route path="/settings" element={isLoggedIn ? <SettingsPage /> : <Navigate to="/login" />} />
          <Route path="/subscription" element={isLoggedIn ? <SubscriptionComponent /> : <Navigate to="/login" />} />
          <Route path="/subscription-success" element={isLoggedIn ? <ThankYouPage /> : <Navigate to="/login" />} />
          {/* Redirect to home if route is not found */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
