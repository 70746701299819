import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './LoginPage.css';
import axios from 'axios';

const LoginPage = () => {
  const navigate = useNavigate(); // Hook to programmatically navigate

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('hide');
  const [rememberMe, setRememberMe] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    // Add the class to the body when this component mounts
    document.body.classList.add('login-page');

    // Remove the class from the body when this component unmounts
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  const isShowPass = () => {
    passwordType == 'hide' ? setPasswordType('show') : setPasswordType('hide');
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate that email and password are not empty
    if (!email || !password) {
      setNotification({ message: 'Email and password cannot be empty.', type: 'error' });
      return;
    }

    try {
      // Replace 'your-backend-url' with your actual backend API URL
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/login-lite', {
        email,
        password,
      });

      if (response.data.status == "success") {
        setNotification({ message: 'Login successful!', type: 'success' });
        localStorage.setItem('isLogin', true);
        localStorage.setItem('userToken', response.data.token);
        localStorage.setItem('userName', response.data.userInfo.name);

        // Force a page refresh
        window.location.reload();
      } else {
        setNotification({ message: 'Inloggen mislukt. Controleer uw gegevens.', type: 'error' });
      }
    } catch (error) {
      setNotification({ message: 'Er is een fout opgetreden tijdens het inloggen. Probeer het alstublieft opnieuw.', type: 'error' });
    }
  };

  return (
    <div className="login-container">
      <div className='login-logo'>
        <img src="/logo-mail.png" alt="Logo" className="login-logo" />
        {/* <h1 className='light-blue'>Order Alert</h1><p style={{marginTop: '-20px'}}>Snelle Updates & Uitstekende Service</p> */}
      </div>
      
      <h2 className="login-title">Inloggen</h2>
      <span className="gray">Inloggen om verder te gaan</span>

      <form className="login-form" onSubmit={handleLogin}>
        {/* <label htmlFor="email">Email:</label> */}
        <input
          type="text"
          placeholder='E-mail'
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        {/* <label htmlFor="password">Password:</label> */}
        <div className="input-wrapper">
          <input
            type={passwordType == 'hide' ? 'password' : 'text'}
            placeholder='wachtwoord'
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{width: '95%'}}
            required
          />
          <span onClick={isShowPass} className="clear-icon">
            <svg style={{marginLeft: '-5vw', marginBottom: '1vh'}}  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.05671 7.05794C6.8067 7.30804 6.66628 7.64721 6.66634 8.00085C6.6664 8.35448 6.80694 8.6936 7.05704 8.94361C7.30714 9.19362 7.64631 9.33404 7.99994 9.33398C8.35358 9.33392 8.6927 9.19338 8.94271 8.94328M11.1207 11.1154C10.1855 11.7005 9.1031 12.0073 8 12C5.6 12 3.6 10.6667 2 8.00002C2.848 6.58669 3.808 5.54802 4.88 4.88402M6.78667 4.12002C7.18603 4.03917 7.59254 3.99897 8 4.00002C10.4 4.00002 12.4 5.33335 14 8.00002C13.556 8.74002 13.0807 9.37802 12.5747 9.91335M2 2L14 14" stroke="#ACB5BB" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </div>

        <div className="remember-me">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            className='gray'
          />
          <label className='gray' htmlFor="rememberMe">Onthoud mij</label>
        </div>

        <button type="submit">Inloggen</button>
      </form>

      <br></br>
      <span className="gray">Heb je geen account?</span> <a className="login-sub-text" style={{ textDecoration: 'none' }}>Verzoek om een account aan te maken</a>

      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default LoginPage;
