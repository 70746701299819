import React, { useState } from 'react';
import axios from 'axios';
import BottomNavigation from '../components/BottomNavigation';
import './SubscriptionComponent.css'; // Create CSS for this modal

const token = localStorage.getItem('userToken');

const createPayment = async (amount, description, email) => {
    try {
        const response = await axios.post('https://demo-be-tailor.cimeo.nl/api/admin/create-payment', {
            amount: amount,
            description: description,
            email: email
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Add token to the request headers
            }
        });

        const data = response.data;

        if (data._links && data._links.checkout) {
            window.location.href = data._links.checkout.href; // Redirect to Mollie checkout
        } else {
            console.error('Failed to create payment:', data);
        }
    } catch (error) {
        console.error('Error during payment creation:', error);
    }
};

const SubscriptionCard = ({ title, price, duration, onSelect }) => (
    <div
        className='subscription-card'
        onClick={onSelect}
    >
        <h3>{title}</h3>
        <p>{duration}</p>
        <h2>€{price}</h2>
    </div>
);

const SubscriptionPlans = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSelectPlan = (price, duration) => {
        setLoading(true);
        const description = `${duration} Subscription`;
        createPayment(price.toFixed(2), description, email);
    };
    
    if (loading) {
      return (
          <div>
              <h2>Laden...</h2>
              <BottomNavigation />
          </div>
      );
    } else {
      return (
          <div>
              <h4>This will billed to your Company Email</h4>
              <h3>Select a Subscription Plan:</h3>
              <div className="card-container">
                  <SubscriptionCard
                      title="Basic Monthly"
                      price={10}
                      duration="1 Month"
                      onSelect={() => handleSelectPlan(10, '1 Month')}
                  />
                  <SubscriptionCard
                      title="Package 3 Months"
                      price={28}
                      duration="3 Months"
                      onSelect={() => handleSelectPlan(28, '3 Months')}
                  />
                  <SubscriptionCard
                      title="Annually"
                      price={110}
                      duration="12 Months"
                      onSelect={() => handleSelectPlan(110, 'Annually')}
                  />
              </div>
              <BottomNavigation />
          </div>
      );
    }

};

export default SubscriptionPlans;
